<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title">
                {{
                  $route.params.id
                    ? $t("orderMsgs.editOrder")
                    : $t("orderMsgs.addOrder")
                }}
              </h4>
            </div>
          </div>
          <div class="card-body">
            <div>
              <form @submit.prevent="onSubmit">
                <div class="row">
                  <div
                    class="form-group col-md-12"
                    v-if="$route.params.id"
                    id="orderNumberRow"
                  >
                    <label for="orderNumber">{{
                      $t("orderMsgs.orderNum")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="isOrderNumInvalid ? 'is-invalid' : ''"
                      id="orderNumber"
                      :placeholder="$t('orderMsgs.orderNum')"
                      v-model="orderNumber"
                      :disabled="getLoggedInUser.role !== USER_ROLES.ADMIN"
                      required
                    />
                    <div class="invalid-feedback" v-if="isOrderNumInvalid">
                      {{ $t("orderMsgs.orderNumExists") }}
                    </div>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="customer">{{
                      $t("customerMsgs.customer")
                    }}</label>

                    <v-select
                      id="customer"
                      class="form-control v-select-custom"
                      label="name"
                      v-model="customer"
                      :reduce="(customer) => customer.id"
                      :placeholder="
                        $t('customerInvoiceMsgs.customerPlaceholder')
                      "
                      :options="allCustomers"
                      :loading="areCustomersLoading"
                      :filterBy="filterCustomers"
                      @input="onCustomerChange"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!customer"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>

                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="costCenter">
                      {{ $t("costCenterMsgs.costCenter") }}
                      <a
                        href="#"
                        v-if="customer"
                        @click.prevent="openPostCostCenterModal()"
                      >
                        {{ $t("costCenterMsgs.quickAddCostCenter") }}
                      </a>
                    </label>

                    <v-select
                      id="costCenter"
                      class="form-control v-select-custom"
                      label="name"
                      v-model="costCenter"
                      :reduce="(costCenter) => costCenter.id"
                      :placeholder="$t('orderMsgs.costCenterPlaceholder')"
                      :options="allCostCenters"
                      :loading="areCostCentersLoading"
                      :filterBy="onCostCenterFilter"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!costCenter"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <template slot="option" slot-scope="option">
                        <i>{{ option.center_number }}</i>
                        <span v-if="option.name"> - {{ option.name }}</span>
                      </template>
                      />
                      <template slot="selected-option" slot-scope="option">
                        <i>{{ option.center_number }}</i>
                        <span v-if="option.name" class="ml-1">
                          - {{ option.name }}</span
                        >
                      </template>
                    </v-select>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="constructionProj">{{
                      $t("orderMsgs.constructionProj")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="constructionProj"
                      :placeholder="$t('orderMsgs.constructionProj')"
                      v-model="constructionProj"
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label for="sheetNums">{{
                      $t("orderMsgs.sheetNums")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="sheetNums"
                      :placeholder="$t('orderMsgs.sheetNums')"
                      v-model="sheetNums"
                      required
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label for="receivedDate">{{
                      $t("orderMsgs.receivedDate")
                    }}</label>

                    <b-form-datepicker
                      v-model="receivedDate"
                      :locale="getCurrLanguage"
                      required
                    ></b-form-datepicker>

                    <!-- Alternative datepicker. Use if needed. -->
                    <!-- <input
                      type="date"
                      class="form-control"
                      id="receivedDate"
                      :placeholder="$t('orderMsgs.receivedDate')"
                      v-model="receivedDate"
                      required
                    /> -->
                  </div>

                  <!-- Costs Related -->

                  <div class="form-group col-md-12" v-if="$route.params.id">
                    <label for="laborHrs">{{ $t("orderMsgs.laborHrs") }}</label>
                    <input
                      class="form-control"
                      :class="!isLaborHrsFormulaValid ? 'is-invalid' : ''"
                      id="laborHrs"
                      :placeholder="$t('orderMsgs.laborHrs')"
                      v-model="laborHrs"
                      @blur="sumLaborHrs"
                    />

                    <div
                      class="invalid-feedback"
                      v-if="!isLaborHrsFormulaValid"
                    >
                      {{ $t("orderMsgs.laborHrsFormulaFormatInvalid") }}
                    </div>
                  </div>

                  <!-- Revenue Related -->

                  <div class="form-group col-md-12">
                    <div
                      class="
                        custom-control
                        custom-switch
                        custom-switch-color
                        custom-control-inline
                      "
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input bg-success"
                        id="customSwitch02"
                        v-model="enableRevFactor"
                      />
                      <label
                        class="custom-control-label"
                        for="customSwitch02"
                        >{{ $t("customerMsgs.enableRevFactorMsg") }}</label
                      >
                    </div>
                  </div>

                  <div class="form-group col-md-12" v-if="enableRevFactor">
                    <label for="revenueFactor">{{
                      $t("customerMsgs.revFactor")
                    }}</label>
                    <currency-input
                      class="form-control"
                      id="revenueFactor"
                      :placeholder="`${$t('customerMsgs.revFactor')} / ${$t(
                        'customerMsgs.discountRate'
                      )}`"
                      v-model="revenueFactor"
                      :currency="null"
                      :precision="2"
                      :allow-negative="false"
                      required
                    />
                  </div>

                  <div class="form-group col-md-6" v-if="$route.params.id">
                    <label for="materialRev">{{
                      $t("orderMsgs.materialRev")
                    }}</label>
                    <currency-input
                      class="form-control"
                      id="materialRev"
                      :placeholder="$t('orderMsgs.materialRev')"
                      v-model="materialRev"
                      :precision="2"
                      :allow-negative="false"
                    />
                  </div>

                  <div class="form-group col-md-6" v-if="$route.params.id">
                    <label for="laborRev">{{ $t("orderMsgs.laborRev") }}</label>
                    <currency-input
                      class="form-control"
                      id="laborRev"
                      :placeholder="$t('orderMsgs.laborRev')"
                      v-model="laborRev"
                      :precision="2"
                      :allow-negative="false"
                    />
                  </div>
                  <!-- only available while external cost excepted -->
                  <div class="form-group col-md-12">
                    <div
                      class="
                        custom-control
                        custom-switch
                        custom-switch-color
                        custom-control-inline
                      "
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input bg-success"
                        id="customSwitch03"
                        v-model="enableCostExp"
                      />
                      <label
                        class="custom-control-label"
                        for="customSwitch03"
                        >{{ $t("orderMsgs.enableExternalCost") }}</label
                      >
                    </div>
                  </div>

                  <!-- <div class="form-group col-md-6" v-if="enableCostExp">
                    <label for="estimateAmt">{{
                      $t("orderMsgs.estAmount")
                    }}</label>
                    <currency-input
                      class="form-control"
                      id="estimateAmt"
                      :placeholder="`${$t('orderMsgs.estAmount')}`"
                      v-model="estimateFactor"
                      :precision="2"
                      :allow-negative="false"
                    />
                  </div> -->

                  
                  <!-- <div class="form-group col-md-6" v-if="enableCostExp">
                    <label for="typeofCost">{{
                      $t("orderMsgs.costType")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="typeofCost"
                      :placeholder="$t('orderMsgs.costType')"
                      v-model="costType"
                    />
                  </div> -->

                  <!-- <div class="form-group col-md-6" v-if="enableCostExp">
                    <label for="supplier">{{
                      $t("orderMsgs.supplier")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="supplier"
                      :placeholder="$t('orderMsgs.supplier')"
                      v-model="supplier"
                    />
                 </div> -->

                <div class="form-group col-md-12" v-if="enableCostExp">
                    <label for="invoiceSupplierFile">
                      {{ $t('orderMsgs.supplierinvoice') }}
                    </label>
                    <b-form-file
                      :placeholder="$t('orderMsgs.attachInvoiceFile')"
                      :drop-placeholder="$t('generalMsgs.dropFilesPlaceholder')"
                      :browse-text="$t('generalMsgs.browse')"
                      class="form-control"
                      v-model="invoiceFile"
                      v-if="!fileURL && !invoiceFile"
                      @input="uploadInvoiceFile"   
                    ></b-form-file>

                    <!-- Attached files -->
                    <div class="mt-2">
                      <div class="row" v-if="fileURL || invoiceFile">
                        <div :class="isUploading ? 'col-md-7 col-xxl-9' : 'col-md-9 col-xxl-10'">
                          <i class="fas fa-file text-primary mr-1" />
                          <span>{{ createFileName(fileURL) }}</span>
                        </div>
                        <div class="col-md-2 col-xxl-1 text-right" v-if="isUploading">
                          <b-progress :value="uploadPercent" :max="100" animated class="mt-1"></b-progress>
                        </div>
                        <div class="text-right col-md-3 col-xxl-2">
                          <a href="#" class="text-danger" @click.prevent="removeInvoiceFile()">
                            <i class="fas fa-times-circle" />
                            {{ $t('remove') }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Only available while adding order -->
                  <div
                    class="form-group col-md-12 col-xxl-12 mt-2"
                    v-if="!$route.params.id"
                  >
                    <label for="drawingFiles">{{
                      $t("orderMsgs.drawingFiles")
                    }}</label>

                    <div class="form-group">
                      <div
                        class="
                          custom-control
                          custom-switch
                          custom-switch-color
                          custom-control-inline
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input bg-success"
                          id="customSwitch04"
                          v-model="enableEmailDrawingFiles"
                        />
                        <label
                          class="custom-control-label"
                          for="customSwitch04"
                        >
                          {{ $t("orderMsgs.enableEmailDrawingFilesMsg") }}
                        </label>
                      </div>
                    </div>

                    <div class="form-group" v-if="enableEmailDrawingFiles">
                      <label for="calculator">
                        {{ $t("roleTitles.calculator") }}
                      </label>
                      <v-select
                        id="calculator"
                        class="form-control v-select-custom"
                        label="email"
                        v-model="calculator"
                        :reduce="(calculator) => calculator.id"
                        :placeholder="$t('orderMsgs.calculatorPlaceholder')"
                        :options="allCalculators"
                        :loading="isCalculatorsLoading"
                        :required="enableEmailDrawingFiles && !calculator"
                      >
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :required="!calculator"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.first_name }} {{ option.last_name }}
                        </template>
                        />
                        <template slot="selected-option" slot-scope="option">
                          {{ option.first_name }} {{ option.last_name }}
                        </template>
                      </v-select>
                    </div>

                    <div class="form-group" v-if="enableEmailDrawingFiles">
                      <label for="message">
                        {{ $t("orderMsgs.message") }}
                      </label>
                      <textarea
                        class="form-control"
                        id="message"
                        :placeholder="$t('orderMsgs.messagePlaceholder')"
                        v-model="message"
                        rows="5"
                      />
                    </div>

                    <b-form-file
                      :placeholder="$t('orderMsgs.attachDrawingFilesToOrder')"
                      :drop-placeholder="$t('generalMsgs.dropFilesPlaceholder')"
                      :browse-text="$t('generalMsgs.browse')"
                      class="form-control"
                      v-model="drawingFilesBuff"
                      multiple
                    >
                    </b-form-file>

                    <!-- Attached files -->
                    <div class="mt-2">
                      <div
                        class="row"
                        v-for="(fileData, index) in drawingFiles"
                        :key="index"
                      >
                        <div
                          :class="
                            fileData.isUploading
                              ? 'col-md-8 col-xxl-10'
                              : 'col-md-10 col-xxl-11'
                          "
                        >
                          <i class="fas fa-file text-primary" />
                          {{ fileData.file.name }}
                        </div>
                        <div
                          class="col-md-2 col-xxl-1 text-right"
                          v-if="fileData.isUploading"
                        >
                          <b-progress
                            :value="fileData.uploadPercent"
                            :max="100"
                            animated
                            class="mt-1"
                          ></b-progress>
                        </div>
                        <div class="text-right col-md-2 col-xxl-1">
                          <a
                            href="#"
                            class="text-danger"
                            @click.prevent="removeAttachedDrawFile(index)"
                          >
                            <i class="fas fa-times-circle" /> {{ $t("remove") }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-right">
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="isFormLoading"
                    style="min-width: 100px"
                  >
                    <i
                      v-if="isFormLoading"
                      class="fas fa-circle-notch fa-spin"
                    />
                    <span v-else>{{
                      $route.params.id ? $t("update") : $t("add")
                    }}</span>
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <post-cost-center-modal
      :showModal.sync="modals.showPostCostCenterModal"
      :customerId="customer"
      :customerName="customerName"
      :costCenterId="modals.selectedCostCenterId"
      @close="hidePostCostCenterModal"
      @closeUpdate="hideUpdatePostCostCenterModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import vSelect from "vue-select";
import { USER_ROLES, LABOR_HRS_SUM_REGEX } from "../../common/constants";
import PostCostCenterModal from "../Customers/CostCenters/PostCostCenterModal";
import { get, sum, find } from "lodash";
import axios from "axios";
import { parseFileNameFromUrl } from "../../common/utils";

export default {
  name: "PostOrder",

  data() {
    return {
      USER_ROLES,

      originalOrderNum: "",
      isOrderNumInvalid: false,

      orderNumber: "",
      message: "",
      customer: null,
      calculator: null,
      customerName: null,
      costCenter: null,
      constructionProj: "",
      costType: "",
      supplier: "",
      sheetNums: "",
      receivedDate: moment().format("YYYY-MM-DD"),
      laborHrs: "0",
      isLaborHrsFormulaValid: true,
      enableRevFactor: false,
      enableCostExp: false,
      revenueFactor: 1,
      estimateFactor: null,
      materialRev: 0,
      laborRev: 0,

      drawingFiles: [],
      drawingFilesBuff: [],
      invoiceFile: null,
      fileURL: null,
      enableEmailDrawingFiles: false,

      allCustomers: [],
      allCostCenters: [],
      allCalculators: [],
      uploadPercent: 0,

      isLoading: false,
      isUploading: false,
      isCalculatorsLoading: false,
      areCostCentersLoading: false,
      areCustomersLoading: false,

      modals: {
        showPostCostCenterModal: false,
        selectedCostCenterId: null,
      },
    };
  },

  components: { PostCostCenterModal, vSelect },

  methods: {
    ...mapActions([
      "createOrder",
      "updateOrder",
      "getOrder",
      "getAllCustomers",
      "getAllCostCenters",
      "validateOrderNumber",
      "getDrawingFilesPresignedUrl",
      "uploadToPresignedUrl",
      "getAllCalculators",
      "getInvoiceFilePresignedUrl",
    ]),
    parseFileNameFromUrl,

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },

    cleanFloat(value) {
      return parseFloat(value.replace(",", "."));
    },

    floatToString(value) {
      return value.toString().replace(".", ",");
    },

    sumLaborHrs() {
      this.isLaborHrsFormulaValid = true;
      this.laborHrs = this.laborHrs.replaceAll(" ", "");
      if (LABOR_HRS_SUM_REGEX.test(this.laborHrs)) {
        this.laborHrs = this.floatToString(
          sum(this.laborHrs.split("+").map((indHrs) => this.cleanFloat(indHrs)))
        );
      } else {
        this.isLaborHrsFormulaValid = false;
      }
    },

    async fetchCustomers(params = {}) {
      this.areCustomersLoading = true;

      const response = await this.getAllCustomers({
        // limit: this.pageSize,
        // offset: (pageNum - 1) * this.pageSize,
        ordering: "name",
        ...params,
      });
      this.allCustomers = response.data;
      // this.currentPage = pageNum;
      // this.totalCustomers = response.data.count;
      this.areCustomersLoading = false;
    },

    async fetchCostCenters(params = {}) {
      this.areCostCentersLoading = true;

      try {
        const response = await this.getAllCostCenters({
          // limit: this.pageSize,
          // offset: (pageNum - 1) * this.pageSize,
          ordering: "name",
          customer: this.customer,
          ...params,
        });
        this.allCostCenters = response.data;
        // this.allCostCenters = response.data.results;
        // this.currentPage = pageNum;
        // this.totalCostCenters = response.data.count;
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.areCostCentersLoading = false;
    },

    async fetchCalculators(params = {}) {
      this.isCalculatorsLoading = true;
      try {
        const response = await this.getAllCalculators({
          // limit: this.pageSize,
          // offset: (pageNum - 1) * this.pageSize,
          ...params,
        });
        this.allCalculators = response.data;
        this.calculator = response.data.length
          ? get(
              find(response.data, (calc) => calc.is_default_calculator),
              "id",
              null
            )
          : null;
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.isCalculatorsLoading = false;
    },

    async onCustomerChange() {
      if (!this.customer) {
        this.costCenter = this.customerName = this.revenueFactor = null;
        this.enableRevFactor = false;
        this.allCostCenters = [];
      } else {
        this.costCenter = null;
        this.fetchCostCenters();

        const customer = this.allCustomers.find(
          (cust) => cust.id === this.customer
        );
        this.customerName = customer.name;
        this.revenueFactor = customer.revenue_factor;
        this.enableRevFactor = customer.revenue_factor === 1 ? false : true;
      }
    },

    onCostCenterFilter(option, label, search) {
      return (
        (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
        (option.center_number || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    },
    createFileName(fileURL) {
      let filename
      if (fileURL) {
        filename = parseFileNameFromUrl(fileURL, true)
      }
      else {
        filename = this.invoiceFile.name
      }
      return filename
    },
    filterCustomers(option, _label, search) {
      const lowerSearch = search.toLowerCase();
      return (
        (option.name || "").toLowerCase().includes(lowerSearch) ||
        (option.customer_number || "").toLowerCase().includes(lowerSearch)
      );
    },

    removeAttachedDrawFile(fileIndex) {
      if (this.drawingFiles[fileIndex].uploadCancelTokenSource) {
        this.drawingFiles[fileIndex].uploadCancelTokenSource.cancel();
      }
      this.drawingFiles.splice(fileIndex, 1);
    },

    async uploadDrawFile(fileData, url) {
      fileData["url"] = url.split("?")[0];

      try {
        await this.uploadToPresignedUrl({
          url: url,
          file: fileData.file,
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            }.bind(fileData),
            cancelToken: fileData.uploadCancelTokenSource.token,
          },
        });
      } catch (error) {
        // Handle upload error here (if needed).
      }

      fileData.uploadCancelTokenSource = null;
      fileData.isUploading = false;
      fileData.uploadPercent = 100;
    },

    async uploadAttachedDrawFiles(files) {
      try {
        const urlResp = await this.getDrawingFilesPresignedUrl(
          files.map((fileData) => ({
            file_name: fileData.file.name,
            content_type: fileData.file.type,
          }))
        );

        files.map((fileData, index) =>
          this.uploadDrawFile(fileData, urlResp.upload_urls[index])
        );
      } catch (error) {
        // Handle upload error here (if needed).
      }
    },

    openPostCostCenterModal() {
      this.modals.showPostCostCenterModal = true;
    },

    hidePostCostCenterModal() {
      this.modals.showPostCostCenterModal = false;
    },

    hideUpdatePostCostCenterModal(center) {
      this.hidePostCostCenterModal();
      this.costCenter = center.id;
      this.allCostCenters.unshift(center);
    },

    async uploadInvoiceFile() {
      this.uploadCancelTokenSource = axios.CancelToken.source();
      this.isUploading = true;

      try {
        const urlResp = await this.getInvoiceFilePresignedUrl({
          file_name: this.invoiceFile.name,
          content_type: this.invoiceFile.type,
        });
        this.fileURL = urlResp.upload_url.split('?')[0];
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.invoiceFile,
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource.token,
          },
        });
      } catch (error) {
        this.fileURL = this.invoiceFile = null;
      }

      this.uploadCancelTokenSource = null;
      this.isUploading = false;
      this.uploadPercent = 0;
  },

  removeInvoiceFile() {
      if (this.uploadCancelTokenSource) {
        this.uploadCancelTokenSource.cancel();
      }
      this.invoiceFile = this.fileURL = null;
      this.uploadPercent = 0;
    },

    async onSubmit() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   this.makeToast("danger", this.$t("formErrorMsg"));
      // } else {
      this.isLoading = true;
      this.isOrderNumInvalid = false;

      if (this.laborHrs && !LABOR_HRS_SUM_REGEX.test(this.laborHrs)) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));

        // Scrolling to the invalid id item.
        this.$nextTick(() => {
          window.scrollTo({
            top: document.querySelector("#laborHrs").offsetTop,
            left: document.querySelector("#laborHrs").offsetLeft,
            behavior: "smooth",
          });
        });
      } else {
        try {
          if (this.orderNumber && this.originalOrderNum != this.orderNumber) {
            await this.validateOrderNumber(this.orderNumber);
          }

          const orderData = {
            customer: this.customer,
            cost_center: this.costCenter,
            construction_project: this.constructionProj,
            sheet_numbers: this.sheetNums,
            received_date: this.receivedDate,
            revenue_factor:
              this.enableRevFactor &&
              (this.revenueFactor || this.revenueFactor === 0)
                ? this.revenueFactor
                : 1,
            ...(this.orderNumber &&
              this.getLoggedInUser.role === USER_ROLES.ADMIN && {
                order_number: this.orderNumber,
              }),
            ...(this.laborHrs && {
              labor_hours: this.cleanFloat(this.laborHrs),
            }),
            ...((this.materialRev || this.materialRev === 0) && {
              material_revenue: this.materialRev,
            }),
            ...((this.laborRev || this.laborRev === 0) && {
              labor_revenue: this.laborRev,
            }),
            ...(get(this.drawingFiles, "length") && {
              drawing_files: this.drawingFiles.map((fileData) => fileData.url),
            }),
            ...(this.calculator &&
              this.enableEmailDrawingFiles && { calculator: this.calculator }),
            ...(this.message &&
              this.enableEmailDrawingFiles && {
                calculator_message: this.message,
            }),
            external_cost_expected: this.enableCostExp,
            estimated_amount: this.estimateFactor,
            type_of_costs: this.costType,
            supplier: this.supplier,
            supplier_invoice: this.fileURL      
          };

          let order;
          if (this.$route.params.id) {
            order = (
              await this.updateOrder({
                id: this.$route.params.id,
                data: orderData,
              })
            ).data.data;
            this.makeToast("success", this.$t("orderMsgs.orderUpdated"));
          } else {
            order = (await this.createOrder(orderData)).data.data;
            this.makeToast("success", this.$t("orderMsgs.orderAdded"));
          }
          this.isLoading = false;
          if (this.$route.query.redirect) {
            this.$router.push({
              name: this.$route.query.redirect,
              params: { order: order.id },
            });
          } else this.$router.push({ name: "list-orders" });
        } catch (error) {
          if (error.response.status === 406) {
            this.isOrderNumInvalid = true;
            this.makeToast("danger", this.$t("orderMsgs.orderNumExists"));

            // Scrolling to the invalid id item.
            this.$nextTick(() => {
              window.scrollTo({
                top: document.querySelector("#orderNumberRow").offsetTop,
                left: document.querySelector("#orderNumberRow").offsetLeft,
                behavior: "smooth",
              });
            });
          } else {
            this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
          }
        }
      }

      this.isLoading = false;
      // }
    },
  },

  // Reset data on before entering the route.
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.customer = vm.costCenter = vm.calculator = null;
      vm.orderNumber = vm.constructionProj = vm.sheetNums = "";
      vm.laborHrs = vm.materialRev = vm.laborRev = 0;
      vm.receivedDate = moment().format("YYYY-MM-DD");
      vm.enableRevFactor = vm.enableEmailDrawingFiles = false;
      vm.revenueFactor = 1;
      vm.message = "";
    });
  },

  async mounted() {
    this.fetchCalculators();
    this.fetchCustomers();
    if (this.$route.params.id) {
      try {
        const order = (await this.getOrder(this.$route.params.id)).data;
        this.originalOrderNum = this.orderNumber = order.order_number;
        this.customer = order.customer.id;
        this.customerName = order.customer.name;
        this.costCenter = order.cost_center.id;
        this.constructionProj = order.construction_project;
        this.sheetNums = order.sheet_numbers;
        this.receivedDate = order.received_date;
        this.laborHrs = this.floatToString(order.labor_hours);
        this.materialRev = order.material_revenue;
        this.laborRev = order.labor_revenue;
        this.revenueFactor = order.revenue_factor;
        this.enableRevFactor = order.revenue_factor === 1 ? false : true;
        this.enableCostExp = order.external_cost_expected;
        this.estimateFactor = order.estimated_amount;
        this.costType = order.type_of_costs;
        this.supplier = order.supplier,
        this.fileURL = order.supplier_invoice

        this.fetchCostCenters();
      } catch (err) {
        this.$router.push({ name: "not-found" });
      }
    }
  },

  computed: {
    ...mapGetters(["getCurrLanguage", "getLoggedInUser"]),

    areDrawFilesUploading() {
      return this.drawingFiles.some((fileData) => fileData.isUploading);
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.isCalculatorsLoading ||
        this.areCostCentersLoading ||
        this.areDrawFilesUploading ||
        this.areCustomersLoading
      );
    },
  },

  watch: {
    async drawingFilesBuff(filesVal) {
      if (get(filesVal, "length")) {
        const newFiles = filesVal.map((file) => ({
          file,
          isUploading: true,
          uploadPercent: 0,
          uploadCancelTokenSource: axios.CancelToken.source(),
        }));
        this.drawingFilesBuff = [];
        this.drawingFiles = this.drawingFiles.concat(newFiles);
        this.uploadAttachedDrawFiles(newFiles);
      }
    },
    showModal(value) {
      if (value && this.customer) {
        this.fetchCostCenters();
        this.$refs["customer-cost-centers-modal"].show();
      }
    },
  },

  beforeDestroy() {
    // Canceling any ongoing upload on page change.
    this.drawingFiles.forEach((fileData) => {
      if (fileData.uploadCancelTokenSource) {
        fileData.uploadCancelTokenSource.cancel();
      }
    });
  },
};
</script>
